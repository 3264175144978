export const Urls = {
    base_url : 'https://face.covitor.ai/',
    login : 'api/v1/user/login/',
    registerUser : 'api/v1/registerUserAdd',
    verifyUser : 'api/v1/verifyUser',
    saveImage : 'api/v1/saveImage',
    authenticateImage : 'api/v1/faceDetection',

    getUserProfile: 'api/v1/fetchUserProfile/',
    addUserProfile: 'api/v1/addUserProfile',

    addVital: 'api/v1/vital/add'
}