<section id="hero">

    <div class="container">
      
      <div class="row justify-content-between">
        <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
          <div data-aos="zoom-out" class="aos-init aos-animate">
            <h1 style="font-size: 41px;">AI Based User Authentication<br>easy as a Selfie Video
              </h1>
            <h2>Get your Key Health Vitals ... Anywhere, Anytime</h2>
            <div class="row justify-content-between">
             
                <div class="col-md-4 wid-xt mt-5">
                  <div class="text-center text-lg-start">
                    <a routerLink="/authenticate/registeruser" class="btn-get-started scrollto w-100 text-center tx-fon">Register User</a>
                  </div>
                </div>
                <div class="col-md-4 wid-xt mt-5">
                  <div class="text-center text-lg-start">
                    <a routerLink="/authenticate/verifyuser" class="btn-get-started scrollto w-100 text-center tx-fon">Verify User</a>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
        <div class="col-lg-4 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="zoom-out" data-aos-delay="300">
          <img src="assets/images/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>

    <svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
      <defs>
        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z">
      </path></defs>
      <g class="wave1">
        <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)">
      </use></g>
      <g class="wave2">
        <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)">
      </use></g>
      <g class="wave3">
        <use xlink:href="#wave-path" x="50" y="9" fill="#fff">
      </use></g>
    </svg>

  </section>