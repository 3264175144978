import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class LoginHttpInterceptor implements HttpInterceptor {

    constructor() {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.includes('user/login')) {
            req = req.clone({
                headers: req.headers.append('Token', `Bearer ${localStorage.getItem('token')}`)
            });
            req = req.clone({
                headers: req.headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
            });
        }
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    location.reload();
                }
                return throwError(error);
            })
        )
    }

}