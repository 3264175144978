import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DocserviceService } from '../docservice.service'
import { HttpClient } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service'
import { Router } from '@angular/router'
import { RegistrationService } from './service/registration.service'



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent implements OnInit {

  spinner = true;
  isNavbarCollapsed = false;
  
  constructor(public router: Router, private registerService: RegistrationService){
    this.router.navigate(['/home']);
  }
  
  ngOnInit() {
    this.spinner = true;
    this.registerService.login().subscribe({
      next: (res: any) => {
        localStorage.setItem('token', res.data.access_token);
      },
      error: (err: any) => {

      }
    }).add(() => {
      this.spinner = false;
    })
  }

  ngAfterViewInit() {
    
  }

  
}
