import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Urls } from '../constant/url.constant';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  isRegister = false;
  userDetails: any = null;

  constructor(private http: HttpClient) { }

  login(): Observable<any> {
    const req = {
      "grant_type": "password",
      "username": "nabinsingh131@gmail.com",
      "email": "nabinsingh131@gmail.com",
      "password": "pass@123",
      "client_id": "qIGiLDHQ7SPi0VDnD45diDfHRzg1PkSGDSGM3PGw",
      "client_secret": "zIr9RQxuqvJc2KrXhzEgSCZjGfPgyr1j8EADmW4ImtANZ22VZ7ItAMoeP5bwofDv0FiQLgDsU1jLHLZct4WJNFwuDkXAyZEu4t86325rVMwGDXxdZk52QnNhSpbJAQoG"
     }
    return this.http.post(Urls.base_url + Urls.login, req);
  }

  registerUser(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.registerUser, req);
  }

  verifyUser(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.verifyUser, req);
  }

  saveImage(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.saveImage, req);
  }

  authenticateImage(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.authenticateImage, req);
  }

  getUserProfile(nn_code: any): Observable<any> {
    return this.http.get(Urls.base_url + Urls.getUserProfile + nn_code);
  }

  addUserProfile(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.addUserProfile, req);
  }

  addVital(req: any): Observable<any> {
    return this.http.post(Urls.base_url + Urls.addVital, req);
  }
}
