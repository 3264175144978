<header id="header" class="fixed-top d-flex align-items-center header-transparent">
  <div class="container d-flex align-items-center justify-content-between">

    <div class="logo">
      <h1><a routerLink="/home"><img src="assets/images/login-logo.png"/></a></h1>
    </div>

    <nav id="navbar" class="navbar">
      <ul [class.showNav]="isNavbarCollapsed">
        <li><a class="nav-link scrollto" (click)="isNavbarCollapsed = false" [class.active]="router?.url == '/home'" routerLink="/home">Home</a></li>
        <li><a class="nav-link scrollto" (click)="isNavbarCollapsed = false" [class.active]="router?.url == '/authenticate/registeruser'" routerLink="/authenticate/registeruser">Register User</a></li>
        <li><a class="nav-link scrollto" (click)="isNavbarCollapsed = false" [class.active]="router?.url == '/authenticate/verifyuser'" routerLink="/authenticate/verifyuser">Verify User</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle" (click)="isNavbarCollapsed = !isNavbarCollapsed"></i>
    </nav>


    <!-- <nav id="navbar" class="navbar navbar-expand-lg navbar-dark bg-dark">
      
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse show" id="navbarNav">
        <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link scrollto" [class.active]="router?.url == '/home'" routerLink="/home">Home</a></li>
        <li><a class="nav-link scrollto" [class.active]="router?.url == '/authenticate/registeruser'" routerLink="/authenticate/registeruser">Register User</a></li>
        <li><a class="nav-link scrollto" [class.active]="router?.url == '/authenticate/verifyuser'" routerLink="/authenticate/verifyuser">Verify User</a></li>
        </ul>      
      </div>
    </nav> -->

  </div>
</header>

<main>
  <router-outlet></router-outlet>
</main>


<footer id="footer">
  <div class="footer-top">
      <div class="container">
          <div class="row">

              <div class="col-lg-6 col-md-6">
                  <div class="footer-info">
                      <h4>Increasing accessibility to health & wellness screening.</h4>
                      <p class="pb-3"><em> 12, 2nd & 3rd floor, Hauz Khas Village, New Delhi - 110016, India.</em></p>
                      <p>
                          <strong>Phone:</strong> +91 9315607159 |
                          <strong>Email:</strong> Contact@bwellhealth.in<br>
                      </p>
                      <div class="social-links mt-3">
                          <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                          <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                          <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                          <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                          <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Important Links</h4>
                  <ul>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">FAQ</a></li>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">Contact Us</a></li>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">Partner</a></li>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">Support</a></li>

                  </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Important Links</h4>
                  <ul>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy Policy</a></li>
                      <li><i class="bx bx-chevron-right"></i> <a href="#">Terms & Conditions</a></li>

                  </ul>
              </div>

          </div>
      </div>
  </div>
</footer>

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>
<div id="preloader" *ngIf="spinner"></div>